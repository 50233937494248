import React, { useEffect, useRef, useState } from "react";
import "./Round369.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";

interface Round369Props {
  game: QuizGame;
}

const Round369: React.FC<Round369Props> = ({
  game
}) => {
  const [videoFading, setVideoFading] = useState(true); // State for fading the video
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5; // Set video to 1.5x speed
    }
  }, []);

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.style.transition = "opacity 1.5s ease";
      videoRef.current.style.opacity = "0"; // Fade out the video
    }

    // Remove the video after fade-out
    setTimeout(() => {
      setVideoFading(false);
    }, 1500); // Match fade-out duration
  };

  return (
    <div className="round-container">
      {/* Fullscreen Video */}
      {videoFading && (
        <video
          ref={videoRef}
          className="fullscreen-video"
          src="/videos/de-slimste-mens-369.mp4"
          autoPlay
          muted
          onEnded={handleVideoEnd}
        />
      )}

      {/* Main Content */}
      <div className={`round round--369`}>
        {/* Current Question */}
        <div className="current-question">
          {game.quiz.round_369 && game.quiz.round_369[game.activeQuestionIndex].image ? (
            <img src={game.quiz.round_369[game.activeQuestionIndex].image} alt="Current Question" />
          ) : (
            <p>No image available</p>
          )}
        </div>

        {/* Separator */}
        <div className="separator"></div>

        {/* Overview */}
        <div className="overview-wrapper">
          {/* Player Standings */}
          <PlayerStandings
            players={game.players}
            activePlayerTimer_start={game.activePlayerTimer_start}
            activePlayerIndex={game.activePlayerIndex}
          />

          {/* Question Overview */}
          <div className="overview">
            {game.quiz.round_369?.map((_, index) => (
              <div
                key={index}
                className={`question ${
                  index === game.activeQuestionIndex
                    ? "question--active"
                    : index < game.activeQuestionIndex
                    ? "question--scoring"
                    : ""
                }`}
              >
                {index + 1}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Round369;
