import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PageLayout } from "../components/PageLayout";

const Admin: React.FC = () => {
  return (
    <PageLayout protect={true}>
      <AuthenticatedTemplate>
        <h1>Admin Screen (Protected)</h1>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h5 className="card-title">Please sign-in to see this screen.</h5>
      </UnauthenticatedTemplate>
    </PageLayout>
  );
};

export default Admin;
