import { Quiz } from "../types/Quiz";
import { AuthenticatedService } from "./AuthenticatedService";

class QuizService extends AuthenticatedService {
  public async getQuizzes(): Promise<Quiz[]> {
    const response = await this.fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/api/admin/quiz`);
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Error fetching quizzes: ${response.statusText}`);
    }
  }

  public async getQuizById(id: string): Promise<Quiz> {
    const response = await this.fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/api/admin/quiz/${id}`);
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Error fetching quiz by ID: ${response.statusText}`);
    }
  }

  public async addQuiz(name: string): Promise<Quiz> {
    const response = await this.fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/api/admin/quiz`, {
      method: "POST",
      body: JSON.stringify({ name }),
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Error adding quiz: ${response.statusText}`);
    }
  }

  public async updateQuiz(id: string, quiz: Quiz): Promise<void> {
    console.log(`Updating quiz with JSON ${ JSON.stringify(quiz)}`);
    const response = await this.fetchWithAuth(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/quiz/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(quiz),
      }
    );
    if (!response.ok) {
      throw new Error(`Error updating quiz: ${response.statusText}`);
    }
  }

  public async deleteQuiz(id: string): Promise<void> {
    const response = await this.fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/api/admin/quiz/${id}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error(`Error deleting quiz: ${response.statusText}`);
    }
  }

  public async startQuizGame(id: string, players: string[]): Promise<string> {
    const response = await this.fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/api/admin/quiz/${id}/start`, {
      method: "POST",
      body: JSON.stringify({ players }),
    });
    if (response.ok) {
      const data = await response.json();
      return data.gameCode; // Return the game code
    } else {
      throw new Error(`Error starting quiz game: ${response.statusText}`);
    }
  }
  
}

export default QuizService;
