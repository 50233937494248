import React, { useEffect, useState } from "react";
import "./RoundOpenDeurAdmin.css";
import { Question_OpenDeur } from "../types/Quiz";
import { Accordion } from "react-bootstrap";

const defaultQuestions: Question_OpenDeur[] = Array.from({ length: 3 }, (_, index) => ({
  id: index + 1,
  text: "",
  video: "",
  answers: ["", "", "", ""],
}));

interface QuizRoundOpenDeurProps {
  questions?: Question_OpenDeur[];
  onUpdateQuestions: (updatedQuestions: Question_OpenDeur[]) => void;
}

const RoundOpenDeurAdmin: React.FC<QuizRoundOpenDeurProps> = ({
  questions = defaultQuestions,
  onUpdateQuestions,
}) => {
  const [localQuestions, setLocalQuestions] = useState<Question_OpenDeur[]>([]);

  // Ensure there are always 3 questions
  useEffect(() => {
    const adjustedQuestions = [...questions];
    if (adjustedQuestions.length < 3) {
      for (let i = adjustedQuestions.length; i < 3; i++) {
        adjustedQuestions.push({ ...defaultQuestions[i] });
      }
    } else if (adjustedQuestions.length > 3) {
      adjustedQuestions.length = 3;
    }
    setLocalQuestions(adjustedQuestions);
  }, [questions]);

  const handleUpdateQuestion = (
    id: number,
    updatedFields: Partial<Question_OpenDeur>
  ) => {
    const updatedQuestions = localQuestions.map((q) =>
      q.id === id ? { ...q, ...updatedFields } : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  const handleUpdateAnswer = (questionId: number, answerIndex: number, value: string) => {
    const updatedQuestions = localQuestions.map((q) =>
      q.id === questionId
        ? {
          ...q,
          answers: q.answers.map((ans, idx) =>
            idx === answerIndex ? value : ans
          ),
        }
        : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  return (
    <div className="open-deur-round">
      <Accordion defaultActiveKey="0">
        {localQuestions.map((question, index) => (
          <Accordion.Item key={question.id} eventKey={index.toString()}>
            <Accordion.Header>Vraag {question.id}: {question.text}</Accordion.Header>
            <Accordion.Body>
              <label className="question-label">
                Vraag:
                <textarea
                  className="question-input"
                  value={question.text}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { text: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                Video URL:
                <input
                  type="text"
                  className="question-input"
                  value={question.video}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { video: e.target.value })
                  }
                />
              </label>
              <div className="answers-section">
                <label className="question-label">Antwoorden:</label>
                {question.answers.map((answer, answerIndex) => (
                  <div key={answerIndex} className="answer-input">
                    <input
                      type="text"
                      className="question-input"
                      value={answer}
                      placeholder={`Antwoord ${answerIndex + 1}`}
                      onChange={(e) =>
                        handleUpdateAnswer(question.id, answerIndex, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default RoundOpenDeurAdmin;
