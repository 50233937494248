import React from 'react';
import { PageLayout } from '../components/PageLayout';

const TermsOfService: React.FC = () => (
  <PageLayout>
    <div className="App">
      <header className="App-header">
        <h1>Terms of Service</h1>
        <p><strong>Effective Date:</strong> November 23, 2024</p>
        <h2>"De Slimste Mens" Application Terms of Service</h2>
        <p>
          Welcome to "De Slimste Mens"! These Terms of Service ("Terms") govern your use of our application.
          By accessing or using our application, you agree to be bound by these Terms.
        </p>

        <h3>1. Acceptance of Terms</h3>
        <p>
          By using the application, you confirm that you are at least 18 years old or have parental/guardian consent.
          If you do not agree to these Terms, please do not use the application.
        </p>

        <h3>2. Description of Services</h3>
        <p>
          "De Slimste Mens" is a quiz application for entertainment purposes. We provide tools to create and manage
          quizzes and interact with other users in a quiz setting.
        </p>

        <h3>3. User Accounts</h3>
        <ul>
          <li>You must create an account and log in using Google OAuth to access the application.</li>
          <li>You are responsible for maintaining the confidentiality of your login credentials and all activities under your account.</li>
        </ul>

        <h3>4. Acceptable Use</h3>
        <p>You agree not to:</p>
        <ul>
          <li>Use the application for unlawful purposes.</li>
          <li>Upload harmful, offensive, or inappropriate content.</li>
          <li>Attempt to interfere with the application’s security or functionality.</li>
        </ul>

        <h3>5. Intellectual Property</h3>
        <p>
          All content in the application, including text, images, and software, is the property of "De Slimste Mens"
          or its licensors. You may not reproduce, distribute, or modify this content without prior written consent.
        </p>

        <h3>6. Limitation of Liability</h3>
        <p>
          We are not liable for any indirect, incidental, or consequential damages arising from your use of the application.
          This includes, but is not limited to, loss of data, profits, or other losses resulting from errors, interruptions,
          or unauthorized access.
        </p>

        <h3>7. Termination</h3>
        <p>
          We reserve the right to terminate or suspend your access to the application at our sole discretion, without notice,
          for any violation of these Terms.
        </p>

        <h3>8. Changes to Terms</h3>
        <p>
          We may update these Terms from time to time. Continued use of the application after changes are posted constitutes
          your acceptance of the revised Terms.
        </p>

        <h3>9. Governing Law</h3>
        <p>
          These Terms are governed by the laws of [Your Country/State], without regard to its conflict of laws principles.
        </p>

        <h3>10. Contact Us</h3>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <ul>
          <li><strong>Email:</strong> [Insert Your Support Email]</li>
          <li><strong>Address:</strong> [Insert Your Company Address]</li>
        </ul>
      </header>
    </div>
  </PageLayout>
);

export default TermsOfService;
