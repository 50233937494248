import React, { useEffect, useState } from "react";
import "./PlayerStandings.css";
import { PlayerWithScore } from "../types/QuizGame";

interface PlayerStandingsProps {
  players: PlayerWithScore[];
  activePlayerIndex: number;
  activePlayerTimer_start: number; // Start time in epoch
}

const PlayerStandings: React.FC<PlayerStandingsProps> = ({
  players,
  activePlayerIndex,
  activePlayerTimer_start,
}) => {
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  // Update current time every second when timer starts
  useEffect(() => {
    if (activePlayerTimer_start > 0) {
      // Initialize current time immediately to avoid delay
      setCurrentTime(Date.now());

      const timer = setInterval(() => {
        setCurrentTime(Date.now());
      }, 1000);

      // Cleanup timer when the component unmounts or timer stops
      return () => clearInterval(timer);
    }
  }, [activePlayerTimer_start]);

  // Calculate remaining time
  const getRemainingTime = (playerIndex: number) => {
    if (playerIndex === activePlayerIndex && activePlayerTimer_start > 0) {
      const elapsedSeconds = Math.max(
        Math.floor((currentTime - activePlayerTimer_start) / 1000),
        0
      );
      const remainingTime = Math.max(
        players[playerIndex].score - elapsedSeconds,
        0
      );
      return remainingTime;
    }
    return players[playerIndex].score; // Default score if not active
  };

  return (
    <div className="player-standings">
      {players.map((player, index) => (
        <div
          key={index}
          className={`player-score ${index === activePlayerIndex ? "active" : ""}`}
        >
          <div className="name">{player.name}</div>
          <div className="score">{getRemainingTime(index)}</div>
        </div>
      ))}
    </div>
  );
};

export default PlayerStandings;
