import { Configuration, LogLevel } from "@azure/msal-browser";

const tenantName = "karimbab2c";
const userFlow = "B2C_1_SignUpSignIn";

// MSAL Configuration object
export const msalConfig: Configuration = {
    auth: {
        clientId: "fb3a57e1-6590-4914-9353-137824e0892e", // Replace with your client ID
        authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${userFlow}`,
        redirectUri: `${process.env.REACT_APP_FRONTEND_URL}`,
        knownAuthorities: [`${tenantName}.b2clogin.com`], // Specify your B2C domain to disable fallback
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

// Scopes for user consent during sign-in
export const loginRequest = {
    scopes: [`https://${tenantName}.onmicrosoft.com/deslimstemens-backend-api/quiz.manage`],
};
