import React, { useEffect, useRef, useState } from "react";
import "./RoundPuzzel.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";

interface RoundPuzzelProps {
  game: QuizGame;
}

const RoundPuzzel: React.FC<RoundPuzzelProps> = ({ game }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoFading, setVideoFading] = useState(true);
  const [shuffledPieces, setShuffledPieces] = useState<JSX.Element[]>([]);
  const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(null);

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.style.transition = "opacity 1.5s ease";
      videoRef.current.style.opacity = "0"; // Fade out the video
    }

    setTimeout(() => {
      setVideoFading(false); // Match fade-out duration
    }, 1500);
  };

  const shuffleArray = (array: any[]) => {
    return [...array].sort(() => Math.random() - 0.5);
  };

  const activeQuestion = game.quiz.round_puzzel?.[game.activeQuestionIndex];

  // Reshuffle only when the question changes
  useEffect(() => {
    if (!activeQuestion || activeQuestion.id === currentQuestionId) {
      return; // Do nothing if the question hasn't changed
    }

    const shuffled = shuffleArray(
      activeQuestion.parts.flatMap((part, partIndex) =>
        part.pieces.map((piece, pieceIndex) => {
          const isAnswered = game.activeQuestionAnswerParts?.[partIndex]?.isAnswered;
          return (
            <div
              key={`${activeQuestion.id}-${partIndex}-${pieceIndex}`}
              className={`puzzle-piece ${isAnswered ? "done" : ""}`}
            >
              {piece}
            </div>
          );
        })
      )
    );

    setShuffledPieces(shuffled);
    setCurrentQuestionId(activeQuestion.id); // Update to the new question
  }, [activeQuestion, currentQuestionId, game.activeQuestionAnswerParts]);

  return (
    <div className="round-container">
      {/* Fullscreen Video */}
      {videoFading && (
        <video
          ref={videoRef}
          className="fullscreen-video"
          src="/videos/de-slimste-mens-puzzel.mp4"
          autoPlay
          muted
          onEnded={handleVideoEnd}
        />
      )}

      {/* Main Content */}
      <div className={`round round--puzzel play`}>
        <div className="question-puzzle">
          {shuffledPieces}
        </div>

        <div className="question-answers">
          {game.activeQuestionAnswerParts?.map((answerPart, index) => (
            <div
              key={index}
              className={`answer-part ${answerPart.isAnswered ? "answered" : ""}`}
            >
              <div className="answer-part__score">30</div>
              <div className="answer-part__answer">{answerPart.answer}</div>
            </div>
          ))}
        </div>

        <PlayerStandings
          players={game.players}
          activePlayerTimer_start={game.activePlayerTimer_start}
          activePlayerIndex={game.activePlayerIndex}
        />
      </div>
    </div>
  );
};

export default RoundPuzzel;
