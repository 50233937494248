import React, { useEffect, useRef, useState, useCallback } from "react";
import "./RoundOpenDeur.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";

interface RoundOpenDeurProps {
  game: QuizGame;
}

const RoundOpenDeur: React.FC<RoundOpenDeurProps> = ({ game }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);
  const [videoFading, setVideoFading] = useState(true);

  const playedVideos = useRef<Set<string>>(new Set());
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<YT.Player | null>(null);
  const youtubeContainerRef = useRef<HTMLDivElement | null>(null);

  // Load YouTube Iframe API
  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    }
  }, []);

  // Watch for Question Index Changes
  useEffect(() => {
    if (game.activeQuestionIndex !== -1 && game.quiz.round_open_deur) {
      const question = game.quiz.round_open_deur[game.activeQuestionIndex];
      if (question && question.video && !playedVideos.current.has(question.video)) {
        setCurrentVideoId(question.video);
        setShowVideo(true);
      }
    } else {
      cleanupYouTubePlayer();
      setShowVideo(false); // Hide video when no question is active
    }
  }, [game.activeQuestionIndex, game.quiz.round_open_deur]);
  
  const handleYouTubeVideoEnd = useCallback(() => {
    if (currentVideoId) {
      playedVideos.current.add(currentVideoId); // Mark video as played
    }
    cleanupYouTubePlayer();
    setCurrentVideoId(null);
    setShowVideo(false); // Trigger React DOM update
  }, [currentVideoId]);

  // Initialize YouTube Player After ShowVideo Becomes True
  useEffect(() => {
    if (showVideo && currentVideoId && youtubeContainerRef.current) {
      if (!playerRef.current && window.YT) {
        playerRef.current = new window.YT.Player(youtubeContainerRef.current, {
          height: "100%",
          width: "100%",
          videoId: currentVideoId,
          playerVars: {
            autoplay: 0,
            controls: 1,
            modestbranding: 1,
            rel: 0,
            mute: 0,
            playsinline: 1,
          },
          events: {
            onReady: (event) => event.target.playVideo(),
            onStateChange: (event) => {
              if (event.data === window.YT.PlayerState.ENDED) {
                handleYouTubeVideoEnd();
              }
            },
          },
        });
      } else if (playerRef.current) {
        playerRef.current.loadVideoById(currentVideoId);
      }
    }
  }, [showVideo, currentVideoId, handleYouTubeVideoEnd]);

  // Cleanup on Component Unmount
  useEffect(() => {
    return () => {
      cleanupYouTubePlayer();
    };
  }, []);
  
  const cleanupYouTubePlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy(); // Destroy YouTube player safely
      playerRef.current = null; // Clear the reference
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.style.transition = "opacity 1.5s ease";
      videoRef.current.style.opacity = "0"; // Fade out the video
    }

    setTimeout(() => {
      setVideoFading(false); // Match fade-out duration
    }, 1500);
  };

  return (
    <div className="round-container">
      {/* Fullscreen Video */}
      {videoFading && (
        <video
          ref={videoRef}
          className="fullscreen-video"
          src="/videos/de-slimste-mens-opendeur.mp4"
          autoPlay
          muted
          onEnded={handleVideoEnd}
        />
      )}

      {/* Fullscreen YouTube Video */}
      {showVideo && (
        <div
          className="fullscreen-video-container"
          ref={youtubeContainerRef}
        ></div>
      )}

      {/* Main Content */}
      {game.activeQuestionIndex === -1 ? (
        <div className={`round round--opendeur select`}>
          <PlayerStandings
            players={game.players}
            activePlayerTimer_start={game.activePlayerTimer_start}
            activePlayerIndex={game.activePlayerIndex}
          />
          <div className="separator"></div>

          <div className="question-selector">
            {game.quiz.round_open_deur?.map((question, index) => (
              <div
                key={index}
                className={`question ${
                  game.selectedQuestionsInRound.includes(index)
                    ? "selected"
                    : ""
                }`}
              >
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${question.video}`}
                  title={`Question ${index + 1}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={`round round--opendeur play`}>
          <div className="question-answers">
            {game.activeQuestionAnswerParts?.map((answerPart, index) => (
              <div
                key={index}
                className={`answer-part ${
                  answerPart.isAnswered ? "answered" : ""
                }`}
              >
                <div className="answer-part__score">20</div>
                <div className="answer-part__answer">
                  {answerPart.answer}
                </div>
              </div>
            ))}
          </div>
          <div className="separator"></div>
          <PlayerStandings
            players={game.players}
            activePlayerTimer_start={game.activePlayerTimer_start}
            activePlayerIndex={game.activePlayerIndex}
          />
        </div>
      )}
    </div>
  );
};

export default RoundOpenDeur;
