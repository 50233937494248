import { IPublicClientApplication, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

export class AuthenticatedService {
  protected instance: IPublicClientApplication;
  protected accounts: any[];

  constructor(instance: IPublicClientApplication, accounts: any[]) {
    this.instance = instance;
    this.accounts = accounts;
  }

  protected async getAccessToken(scopes: string[] = loginRequest.scopes): Promise<string> {
    if (!this.instance || this.accounts.length === 0) {
      throw new Error("MSAL instance is not initialized or user is not logged in.");
    }

    const request = {
      scopes,
      account: this.accounts[0], // Use the active account
    };

    try {
      // Attempt to acquire the token silently
      const response: AuthenticationResult = await this.instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interactive token acquisition
        const interactiveResponse: AuthenticationResult = await this.instance.acquireTokenPopup(request);
        return interactiveResponse.accessToken;
      } else {
        console.error("Error acquiring token:", error);
        throw error;
      }
    }
  }

  protected async fetchWithAuth(url: string, options: RequestInit = {}): Promise<Response> {
    const token = await this.getAccessToken();
    const headers = new Headers(options.headers || {});

    // Add the Authorization header
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      ...options,
      headers,
    };

    return fetch(url, requestOptions);
  }
}
