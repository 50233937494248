import { AuthenticatedService } from "./AuthenticatedService";

class HelloService extends AuthenticatedService {
  async hello(): Promise<string> {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/hello`;

    try {
      const response = await this.fetchWithAuth(url, { method: "GET" });

      if (response.ok) {
        const data = await response.json();
        return data.message;
      } else {
        console.error("API response error:", response.statusText);
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching hello message:", error);
      throw error;
    }
  }
}

export default HelloService;
