import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { Action, QuizGame } from "../types/QuizGame";
import "./QuizMaster.css";  // Import CSS file

const QuizMaster: React.FC = () => {
  const { gameCode } = useParams<{ gameCode: string }>();
  const [game, setGame] = useState<QuizGame | null>(null);
  const [error, setError] = useState<string | null>(null);

  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    if (!gameCode) return;

    if (!socket.current) {
      socket.current = io(process.env.REACT_APP_BACKEND_URL as string);
    }

    console.log(`QuizMaster joining game ${gameCode}`);

    socket.current.emit("join-game", gameCode, (response: { error?: string; game?: QuizGame }) => {
      if (response.error) {
        setError(response.error);
        return;
      }
      if (response.game) {
        setGame(response.game);
      }
    });

    socket.current.on("game-state-changed", (updatedGame: QuizGame) => {
      console.log("Game state updated:", updatedGame);
      setGame(updatedGame);
    });

    return () => {
      console.log("Disconnecting socket...");
      socket.current?.disconnect();
      socket.current = null;
    };
  }, [gameCode]);

  const handleAction = (action: Action) => {
    if (!gameCode || !socket.current) return;

    socket.current.emit("update-game", { gameCode, action }, (response: { error?: string }) => {
      if (response.error) {
        setError(response.error);
      }
    });
  };

  if (error) return <p className="error">Error: {error}</p>;
  if (!game) return <p className="loading">Loading...</p>;

  const activeQuestion =
    game.activeRound === "369"
      ? game.quiz.round_369?.[game.activeQuestionIndex]
      : game.activeRound === "open_deur"
        ? game.quiz.round_open_deur?.[game.activeQuestionIndex]
        : game.activeRound === "puzzel" 
          ? { text: `Puzzel ${game.activeQuestionIndex + 1}` } : undefined;

  return (
    <div className="quiz-master">
      <h1>Quiz Master</h1>
      <h2>Game Code: {gameCode}</h2>

      {/* Show Players and Scores */}
      <h3>Players</h3>
      <ul className="player-list">
        {game.players.map((player, index) => (
          <li
            key={index}
            className={`player ${game.activePlayerIndex === index ? "active-player" : ""}`}
          >
            {player.name} - Score: {player.score}
          </li>
        ))}
      </ul>

      {/* Show Active Question */}
      <h3>Active Question</h3>
      {activeQuestion ? (
        <p className="question-text">{activeQuestion.text}</p>
      ) : (
        <p className="no-question">No active question available</p>
      )}

      {/* Round 369: Show Answer and Correct Button */}
      {game.activeRound === "369" && activeQuestion && "answer" in activeQuestion && (
        <>
          <p className="answer-text">Answer: {activeQuestion.answer}</p>
          {game.allowedActions.includes("correct") && (
            <button onClick={() => handleAction({ type: "correct" })} className="btn btn-success">
              Correct
            </button>
          )}
        </>
      )}

      {/* Round Open Deur: Show Answer Parts */}
      {(game.activeRound === "open_deur" || game.activeRound === "puzzel") 
          && game.activeQuestionAnswerParts && (
        <ul className="answer-parts">
          {game.activeQuestionAnswerParts.map((answerPart, index) => (
            <li
              key={index}
              className={`answer-part ${answerPart.isAnswered ? "answered" : ""}`}
            >
              {answerPart.answer}
              {game.allowedActions.includes("correct") && !answerPart.isAnswered && (
                <button
                  onClick={() => handleAction({ type: "correct", targetIndex: index })}
                  className="btn btn-success"
                >
                  Correct
                </button>
              )}
            </li>
          ))}
        </ul>
      )}

      {/* Other Allowed Actions */}
      <div className="actions">
        {game.allowedActions
          .filter((action) => action !== "correct" && action !== "selectQuestion")
          .map((action) => (
            <button
              key={action}
              onClick={() => handleAction({ type: action })}
              className="btn btn-primary"
            >
              {action}
            </button>
          ))}
      </div>

      {/* Select Questions */}
      {game.allowedActions.includes("selectQuestion") && game.activeRound === "open_deur" && (
        <>
          <h3>Select a Question</h3>
          <ul className="select-questions">
            {game.quiz.round_open_deur?.map((question, index) => {
              const isAlreadySelected = game.selectedQuestionsInRound.includes(index);

              return (
                <li key={index} className={isAlreadySelected ? "already-selected" : ""}>
                  {question.text}

                  {/* Show "Select" button only for unselected questions */}
                  {!isAlreadySelected && (
                    <button
                      onClick={() =>
                        handleAction({ type: "selectQuestion", targetIndex: index })
                      }
                      className="btn btn-warning"
                    >
                      Select
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default QuizMaster;
