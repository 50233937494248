import logo from "../images/logo.svg";
import "./Home.css";
import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/PageLayout";
import HelloService from "../services/HelloService";
import { useService } from "../hooks/useService";

const Home: React.FC = () => {
  const helloService = useService(HelloService);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (!helloService) {
      console.log("HelloService is not initialized yet");
      return;
    }

    const fetchHelloMessage = async () => {
      try {
        const helloMessage = await helloService.hello();
        setMessage(helloMessage);
      } catch (error) {
        console.error("Error fetching hello message:", error);
      }
    };

    fetchHelloMessage();
  }, [helloService]);

  return (
    <PageLayout protect={true}>
      <div className="home-content">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Frontend-Backend Integration</h1>
        <p>{message}</p>
      </div>
    </PageLayout>
  );
};

export default Home;
