import React, { useEffect, useState } from "react";
import "./Round369Admin.css";
import { Question_369 } from "../types/Quiz";
import { Accordion } from "react-bootstrap";

const defaultQuestions: Question_369[] = Array.from({ length: 12 }, (_, index) => ({
  id: index + 1,
  text: "",
  image: "",
  video: "",
  answer: "",
  isGroupQuestion: false,
  description: "",
}));

interface QuizRound369Props {
  questions?: Question_369[];
  onUpdateQuestions: (updatedQuestions: Question_369[]) => void;
}

const Round369Admin: React.FC<QuizRound369Props> = ({ questions = defaultQuestions, onUpdateQuestions }) => {
  const [localQuestions, setLocalQuestions] = useState<Question_369[]>([]);

  // Ensure there are always 12 questions
  useEffect(() => {
    const adjustedQuestions = [...questions];
    if (adjustedQuestions.length < 12) {
      for (let i = adjustedQuestions.length; i < 12; i++) {
        adjustedQuestions.push({ ...defaultQuestions[i] });
      }
    } else if (adjustedQuestions.length > 12) {
      adjustedQuestions.length = 12;
    }
    setLocalQuestions(adjustedQuestions);
  }, [questions]);

  const handleUpdateQuestion = (id: number, updatedFields: Partial<Question_369>) => {
    const updatedQuestions = localQuestions.map((q) =>
      q.id === id ? { ...q, ...updatedFields } : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  return (
    <div className="three-six-nine-round">
      <Accordion defaultActiveKey="0">
        {localQuestions.map((question, index) => (
          <Accordion.Item key={question.id} eventKey={index.toString()}>
            <Accordion.Header>Vraag {question.id}: {question.text}</Accordion.Header>
            <Accordion.Body>
              <label className="question-label">
                Vraag:
                <textarea
                  className="question-input"
                  value={question.text}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { text: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                Afbeelding URL (optioneel):
                <input
                  type="text"
                  className="question-input"
                  value={question.image}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { image: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                Video URL (optioneel):
                <input
                  type="text"
                  className="question-input"
                  value={question.video}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { video: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                Verwacht antwoord (optioneel):
                <input
                  type="text"
                  className="question-input"
                  value={question.answer}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { answer: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                Extra informatie (optioneel):
                <textarea
                  className="question-input"
                  value={question.description}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { description: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                Groepsvraag:
                <input
                  type="checkbox"
                  checked={question.isGroupQuestion}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, {
                      isGroupQuestion: e.target.checked,
                    })
                  }
                />
              </label>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Round369Admin;
