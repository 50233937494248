import React from 'react';
import { PageLayout } from '../components/PageLayout';

const PrivacyPolicy: React.FC = () => (
  <PageLayout>
    <div className="App">
      <header className="App-header">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> November 23, 2024</p>
        <h2>"De Slimste Mens" Application Privacy Policy</h2>
        <p>
          Welcome to "De Slimste Mens"! Your privacy is important to us, and this Privacy Policy
          explains how we collect, use, and protect your personal information when you use our application.
        </p>

        <h3>1. Information We Collect</h3>
        <p>We may collect the following types of personal information:</p>
        <ul>
          <li>
            <strong>Personal Identifiable Information:</strong> Your name, email address, and Google account details
            when you log in using Google OAuth.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you use the application, including the pages you visit
            and actions you perform.
          </li>
        </ul>

        <h3>2. How We Use Your Information</h3>
        <p>We use the information collected for the following purposes:</p>
        <ul>
          <li>To authenticate users and provide secure access to the application.</li>
          <li>To maintain a record of users for administrative purposes.</li>
          <li>To improve and personalize your experience.</li>
        </ul>

        <h3>3. Data Sharing and Disclosure</h3>
        <p>
          We do not sell, rent, or trade your personal information to third parties. Your data may be shared only
          in the following cases:
        </p>
        <ul>
          <li>With service providers (e.g., Azure) to operate and maintain the application.</li>
          <li>If required by law or to protect the rights, property, or safety of our users.</li>
        </ul>

        <h3>4. Data Retention</h3>
        <p>
          We retain your personal data only for as long as necessary to provide our services or as required by law.
        </p>

        <h3>5. Security</h3>
        <p>
          We use appropriate technical and organizational measures to protect your information. However, no method
          of transmission or storage is 100% secure.
        </p>

        <h3>6. Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li>Access, update, or delete your personal information.</li>
          <li>Withdraw your consent for data processing where applicable.</li>
        </ul>

        <h3>7. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated
          effective date. Your continued use of the application after such updates constitutes your acceptance of the changes.
        </p>

        <h3>8. Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <ul>
          <li><strong>Email:</strong> [Insert Your Support Email]</li>
          <li><strong>Address:</strong> [Insert Your Company Address]</li>
        </ul>
      </header>
    </div>
  </PageLayout>
);

export default PrivacyPolicy;
