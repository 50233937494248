import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QuizService from "../services/QuizService";
import { Button, Card, Modal, Form } from "react-bootstrap";
import "./QuizList.css";
import { PageLayout } from "../components/PageLayout";
import { useService } from "../hooks/useService";
import { Quiz } from "../types/Quiz";

const QuizList: React.FC = () => {
  const quizService = useService(QuizService);
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newQuizName, setNewQuizName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!quizService) {
      console.log("QuizService is not initialized yet");
      return;
    }

    const fetchQuizzes = async () => {
      try {
        const data = await quizService.getQuizzes();
        setQuizzes(data);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };

    fetchQuizzes();
  }, [quizService]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setNewQuizName("");
  };

  const handleAddQuiz = async () => {
    if (!quizService) {
      console.error("QuizService is not initialized");
      return;
    }

    if (newQuizName.trim()) {
      try {
        const newQuiz = await quizService.addQuiz(newQuizName);
        setQuizzes([...quizzes, newQuiz]);
        closeModal();
        navigate(`/quizzes/${newQuiz.id}`);
      } catch (error) {
        console.error("Error adding quiz:", error);
      }
    }
  };

  const goToQuizDetail = (id: string) => {
    navigate(`/quizzes/${id}`);
  };

  return (
    <PageLayout protect={true}>
      <div className="quiz-list-container">
        <h1 className="quiz-list-title">Quizzes</h1>
        <div className="quiz-list">
          {quizzes.map((quiz) => (
            <Card
              key={quiz.id}
              className="quiz-card"
              onClick={() => goToQuizDetail(quiz.id)}
            >
              <Card.Body>
                <Card.Title>{quiz.name}</Card.Title>
              </Card.Body>
            </Card>
          ))}
          <Card className="add-quiz-card" onClick={openModal}>
            <Card.Body>
              <h1 className="add-quiz-plus">+</h1>
              <p className="add-quiz-text">Add New Quiz</p>
            </Card.Body>
          </Card>
        </div>

        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Quiz</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Quiz Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newQuizName}
                  onChange={(e) => setNewQuizName(e.target.value)}
                  placeholder="Enter quiz name"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddQuiz}>
              Add Quiz
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </PageLayout>
  );
};

export default QuizList;
