import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

/**
 * Renders a simple sign-out button that uses the redirect logout flow
 */
export const SignOutButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/", // Redirect to the home page after logout
      })
      .catch((e: Error) => {
        console.error("Logout failed:", e);
      });
  };

  return (
    <Button variant="secondary" onClick={handleLogout}>
      Sign Out
    </Button>
  );
};
