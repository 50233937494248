import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

/**
 * Renders a simple sign-in button that uses the redirect login flow
 */
export const SignInButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .catch((e: Error) => {
        console.error("Login failed:", e);
      });
  };

  return (
    <Button variant="primary" onClick={handleLogin}>
      Sign In
    </Button>
  );
};
