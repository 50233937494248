import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

// Create the PublicClientApplication instance
const msalInstance = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Root element not found. Please ensure there is an element with id 'root' in your index.html.");
}

const root = ReactDOM.createRoot(rootElement);

(async () => {
  try {
    // Await the initialization of MSAL
    await msalInstance.initialize();

    // Once initialized, handle the redirect promise
    await msalInstance.handleRedirectPromise();

    // Render the application
    root.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error("Error initializing MSAL or handling redirect:", error);
  }
})();
