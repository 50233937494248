import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuizService from "../services/QuizService";
import { useService } from "../hooks/useService";

const StartQuizGame: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [players, setPlayers] = useState<string[]>(["", "", ""]);
  const [error, setError] = useState<string | null>(null);
  const quizService = useService(QuizService);

  const handleInputChange = (index: number, value: string) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = value;
    setPlayers(updatedPlayers);
  };

  const startGame = async () => {
    if (players.some((player) => player.trim() === "")) {
      setError("All player names are required.");
      return;
    }

    try {
      if (!quizService) {
        setError("Service not initialized");
        return;
      }
  
      if (!id) {
        setError("Quiz ID is missing.");
        return;
      }
      const gameCode = await quizService.startQuizGame(id, players);
      navigate(`/quiz-game/${gameCode}`);
    } catch (error) {
      console.error("Error starting quiz game:", error);
      setError("Failed to start quiz game. Please try again.");
    }
  };

  return (
    <div>
      <h1>Start Quiz Game</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div>
        {players.map((player, index) => (
          <div key={index}>
            <label>
              Player {index + 1}:
              <input
                type="text"
                value={player}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
            </label>
          </div>
        ))}
      </div>
      <button className="btn btn-primary" onClick={startGame}>
        Start Game
      </button>
    </div>
  );
};

export default StartQuizGame;
